.footer {
    display: flex;
    flex-direction: column;
    padding: 64px 189px 48px 180px;
    background-color: var(--primary-color);
    gap: 24px;
    &__container {
        display: flex;
        flex-direction: column;
        img {
            width: 100%;
            margin-bottom: -5px;
        }
    }
    &-row-1 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid var(--text-color);
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
        }
    }
    &-row-2 {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
    }
}

@media only screen and (max-width: 1300px) {
    .footer {
        padding: 48px 70.5px;
    }
}

@media only screen and (max-width: 768px) {
    .footer {
        padding: 48px;
    }
}

@media only screen and (max-width: 568px) {
    .footer {
        padding: 48px 24px;
        svg{
            width: 190px;
        }
    }
}