.investors {
    background-color: var(--primary-color);
    min-height: 100vh;

    header {
        padding: 72px 180px;
        padding-bottom: 0;
        svg {
            cursor: pointer;
        }
    }
    form {
        display: flex;
        flex-direction: column;
        padding: 120px 0;
        width: 536px;
        margin: 0 auto;
        gap: 25px;
    }
    &__title {
        font-family: "Crimson Pro";
        font-weight: 400;
        font-size: 52px;
        line-height: 115%;
        text-transform: capitalize;
    }
    &__input {
        outline: none;
        border: none;
        width: 100%;
        border: 1px solid var(--text-color);
        background: transparent;
        height: 55px;
        padding: 10px 20px;
        font-family: "Nunito", sans-serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 110%;
        color: var(--text-color);
        &::placeholder {
            color: #eee;
        }
    }
    &__form-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        label {
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            input {
                outline: none;
                border: none;
                width: 20px;
                height: 20px;
                border: 1px solid var(--secondary-color);
            }
        }
        a {
            text-decoration: none;
            font-size: 16px;
            font-weight: 500;
            color: var(--secondary-color);
            transition: all 0.3s ease-in-out;
            &:hover {
                color: var(--text-color);
            }
        }
    }
    button {
        width: fit-content;
        margin-top: 16px;
        cursor: pointer;
        background: transparent;
        outline: none;
        border: none;
        padding: 12px 36px;
        border: 2px solid var(--secondary-color);
        font-family: "Crimson Pro";
        font-weight: 400;
        font-size: 28px;
        line-height: 105%;
        text-align: center;
        color: #fff8f2;
        transition: all 0.3s ease-in-out;
        &:hover {
            background: var(--secondary-color);
        }
    }
    &__footer {
        margin: 0 180px;
        margin-top: 100px;
        padding: 24px 0px 48px 0px;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: var(--text-color);
        border-top: 1px solid var(--text-color);
    }
}

@media only screen and (max-width: 1300px) {
    .investors {
        header {
            padding: 70.5px;
        }
        &__footer {
            margin: 0 70.5px;
            margin-top: 90px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .investors {
        header {
            padding: 60px 48px;
        }
        form {
            padding: 80px 48px;
            width: 100%;
            margin: 0 auto;
            gap: 25px;
        }
        &__footer {
            margin: 0 48px;
            margin-top: 60px;
        }
    }
}

@media only screen and (max-width: 500px) {
    .investors {
        header {
            padding: 40px 24px;
            svg {
                width: 220px;
            }
        }
        form {
            padding: 60px 24px;
            button {
                font-size: 24px;
            }
            label {
                font-size: 16px;
            }
        }

        &__title {
            font-size: 42px;
        }
        &__footer {
            margin: 0 24px;
            margin-top: 50px;
        }
    }
}
