@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@200;300;400;500;600;700;800;900&family=Nunito:wght@200;300;400;500;600;700;800;900;1000&display=swap");
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #254252;
  --secondary-color: #fe830c;
  --text-color: #fff8f2;
}

body {
  background-color: #fff;
  color: var(--text-color);
  font-weight: 400;
  /* font-family: 'Crimson Pro', serif; */
  font-size: 20px;
  line-height: 27px;
  font-family: "Nunito", sans-serif;
}
