.home {
    &__hero {
        padding: 72px 70.5px 120px 183px;
        background: var(--primary-color);
        display: flex;
        flex-direction: column;
        gap: 80px;
        &-nav {
            display: flex;
            align-items: center;
            justify-content: space-between;

            button {
                outline: none;
                border: none;
                font-family: "Crimson Pro", sans-serif;
                font-weight: 400;
                font-size: 30px;
                line-height: 33px;
                color: var(--text-color);
                padding: 16px 24px;
                border: 2px solid var(--secondary-color);
                background-color: transparent;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                &:hover {
                    background-color: var(--secondary-color);
                }
            }
        }
        p {
            font-family: "Crimson Pro", sans-serif;
            font-weight: 400;
            font-size: 48px;
            line-height: 53px;
            width: 1000px;
        }
    }
    &__container {
        display: flex;
        padding: 120px 180px;
        gap: 120px;
        &-col {
            display: flex;
            flex-direction: column;
            gap: 36px;
            flex: 1;
        }
        &-title {
            font-family: "Crimson Pro", sans-serif;
            font-weight: 700;
            font-size: 34px;
            line-height: 38px;
            text-transform: capitalize;
            color: #454545;
            padding-bottom: 8px;
            border-bottom: 2px solid var(--secondary-color);
        }
        &-mission {
            font-family: "Crimson Pro", sans-serif;
            font-weight: 500;
            font-size: 32px;
            line-height: 36px;
            color: var(--primary-color);
        }
        &-brand {
            display: flex;
            flex-direction: column;
            gap: 16px;
            p {
                font-weight: 400;
                font-size: 20px;
                line-height: 27px;
                color: #454545;
            }
        }
    }
}

@media only screen and (max-width: 1300px) {
    .home {
        &__hero {
            padding: 72px 70.5px 120px 70.5px;
            p {
                width: 80%;
            }
        }
        &__container {
            display: flex;
            padding: 100px 70.5px;
            gap: 120px;
        }
    }
}
@media only screen and (max-width: 1000px) {
    .home {
        &__hero {
            gap: 60px;
            p {
                width: 80%;
                font-size: 38px;
                line-height: 130%;
            }
        }
        &__container {
            padding: 100px 70.5px;
            gap: 80px;
            &-col {
                gap: 28px;
            }
            &-title {
                font-size: 30px;
                line-height: 115%;
            }
            &-mission {
                font-size: 26px;
                line-height: 115%;
            }
            &-brand {
                p {
                    font-size: 18px;
                    line-height: 115%;
                }
            }
        }
    }
}

@media only screen and (max-width: 860px) {
    .home {
        &__container {
            display: flex;
            flex-direction: column;
        }
    }
}

@media only screen and (max-width: 768px) {
    .home {
        &__hero {
            padding: 60px 48px 100px 48px;
            gap: 50px;
            &-nav {
                svg {
                    width: 230px;
                }
                button {
                    font-size: 24px;
                    line-height: 120%;
                    padding: 12px 18px;
                }
            }
            p {
                font-size: 32px;
                width: 85%;
            }
        }
        &__container {
            padding: 70px 48px 70px 48px;
            gap: 60px;
        }
    }
}

@media only screen and (max-width: 568px) {
    .home {
        &__hero {
            padding: 23px 24px 60px 24px;
            gap: 20px;
            &-nav {
                svg {
                    width: 150px;
                }
                button {
                    font-size: 20px;
                    line-height: 120%;
                    padding: 8px 16px;
                }
            }
            p {
                font-size: 26px;
                width: 85%;
            }
        }
        &__container {
            padding: 40px 24px 40px 24px;
            gap: 50px;
            &-col {
                gap: 24px;
            }
            &-title {
                font-size: 26px;
                padding-bottom: 6px;
            }
            &-mission {
                font-size: 22px;
            }
            &-brand {
                gap: 8px;
                p {
                    font-size: 16px;
                }
            }
        }
    }
}

@media only screen and (max-width: 460px) {
    .home {
        p {
            width: 100%;
            font-size: 24px;
        }
        button {
            font-size: 16px;
        }
        &__container {
            &-brand p {
                font-size: 16px;
            }
            svg{
                transform: scale(0.8);
                margin-left: -16px;
            }
        }

    }
}
